import axios from 'axios';
import Layout from 'components/common/NewLayout';
import Section1 from 'components/inquiry/Section1';
import Title from 'components/inquiry/Title';
import React, { useState } from 'react';

const Inquiry = () => {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const onSubmit = data => {
    if (data.term !== 'Y') {
      window.alert('개인정보 이용에 동의해주세요');
      return;
    }
    setIsSubmiting(true);

    const createQuery = `mutation { create_item (board_id: 4300636478, group_id: "emailed_____", item_name: "[몰파이] 도입문의 - 공통에 새 응답이 접수되었습니다.") { id }}`;

    axios
      .post(
        'https://api.monday.com/v2',
        JSON.stringify({
          query: createQuery,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjMwMDI5NTIwMSwiYWFpIjoxMSwidWlkIjozNTQ3OTEyNCwiaWFkIjoiMjAyMy0xMi0wNFQwMTowNTo1NS4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTI2MzQxNjksInJnbiI6InVzZTEifQ.IgFcORzpDWwyO3pbRP4NdoF4YuosvnSaxID3qrwBwP8',
          },
        },
      )
      .then(res => {
        const updateQuery = `mutation { create_update (item_id: ${res.data.data.create_item.id}, body: "도입 유형: ${data.type}<br/> 기업/브랜드명: ${data.compNm}<br/> 담당자 연락처: ${data.mobile}<br/> 이메일 주소: ${data.id}@${data.id2}<br/> 업종: ${data.sectors}<br/> 마케팅 동의여부:${data.marketing}<br/> 문의 내용:${data.content}") { id }}`;
        axios
          .post(
            'https://api.monday.com/v2',
            JSON.stringify({
              query: updateQuery,
            }),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization:
                  'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjMwMDI5NTIwMSwiYWFpIjoxMSwidWlkIjozNTQ3OTEyNCwiaWFkIjoiMjAyMy0xMi0wNFQwMTowNTo1NS4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTI2MzQxNjksInJnbiI6InVzZTEifQ.IgFcORzpDWwyO3pbRP4NdoF4YuosvnSaxID3qrwBwP8',
              },
            },
          )
          .then(() => {
            alert('도입 문의가 완료 되었습니다.');
            (window as any)?.gtag('event', 'conversion', {
              send_to: 'AW-16483269441/XCF-CNSs0OAZEMH26rM9',
            });
            window.fbq('track', 'CompleteRegistration');
            window.location.reload();
          })
          .catch(() => {
            setIsSubmiting(false);
          });
      });
  };

  return (
    <Layout theme="white">
      <Title />
      <Section1 onSubmit={onSubmit} isSubmiting={isSubmiting} />
    </Layout>
  );
};

export default Inquiry;
