/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import list1Img from 'static/store/store2_list1.png';
import { motion } from 'framer-motion';
import { useIsMobile } from 'core/hooks/useResponsive';
import { ReactComponent as ArrowLeft } from 'static/store/arrow_left.svg';
import { ReactComponent as Pencil } from 'static/store/pencil_simple_line.svg';
import { ReactComponent as Tray } from 'static/store/tray.svg';
import { ReactComponent as User } from 'static/store/user_circle.svg';
import { ReactComponent as Check } from 'static/store/seal_check.svg';
import { ReactComponent as Down } from 'static/store/down.svg';
import { ReactComponent as Close } from 'static/user/mypage/close.svg';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Modal from 'components/common/Modal';

interface Store2Pros {
  onSubmit: (data: any) => void;
  isSubmiting: boolean;
}

const Store2 = ({ onSubmit, isSubmiting }: Store2Pros) => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [modal, setModal] = useState<boolean>(false);

  const handleModalOpen = () => {
    setModal(true);
  };

  const handleModalClose = () => {
    setModal(false);
  };

  const Store2Style = css`
    display: flex;
    justify-content: center;
    background: #f2f3f7;
    .wrap {
      width: 1100px;
      padding: 200px 0px;
      .title {
        font-size: 64px;
        font-weight: 700;
        line-height: 86px;
      }
      .box {
        display: flex;
        margin-top: 82px;
        justify-content: space-between;
        .subTitle {
          font-size: 36px;
          font-weight: 700;
          line-height: 48px;
        }
        .process {
          width: 500px;
          .step {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 32px;
            gap: 32px;
            li {
              width: 500px;
              padding: 24px 32px;
              background: #f9fafb;
              border-radius: 8px;
              display: flex;
              position: relative;
              border: 1px solid #e5e7ee;
              .img {
                width: 32px;
                svg {
                  width: 100%;
                }
              }
              .text {
                margin-left: 32px;
                .sub {
                  font-size: 16px;
                  color: #6079ff;
                  font-weight: 600;
                  line-height: 24px;
                }
                .title {
                  margin-top: 4px;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 30px;
                  color: #000;
                }
              }
              .arrow {
                position: absolute;
                height: 32px;
                bottom: -32px;
                left: 50%;
                transform: translateX(-50%);
              }
              &.on {
                background: rgba(96, 121, 255, 0.1);
                border: 1px dashed #6079ff;
                .text {
                  .sub {
                  }
                }
              }
            }
          }
          .notice {
            margin-top: 32px;
            padding: 24px 32px;
            border-radius: 8px;
            border: 1px dashed #f77;
            background: rgba(255, 119, 119, 0.1);
            strong {
              font-size: 24px;
              font-weight: 700;
              line-height: 36px;
              display: block;
              padding-bottom: 12px;
            }
            li {
              padding: 0 0 0 20px;
              background: transparent;
              font-size: 18px;
              font-weight: 400;
              line-height: 32px;
              position: relative;
            }
            li:before {
              content: '';
              position: absolute;
              top: 11px;
              left: 5px;
              width: 5px;
              height: 5px;
              border-radius: 100%;
              background-color: #8d8d8d;
            }
          }
        }
        .form {
          width: 500px;
          form {
            margin-top: 24px;
            border-radius: 36px;
            background: #fff;
            padding: 40px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
            .formTitle {
              font-size: 24px;
              font-weight: 700;
              line-height: 30px;
              margin-bottom: 32px;
              span {
                margin-top: 6px;
                font-size: 18px;
                font-weight: 500;
                line-height: 30px;
                opacity: 0.6;
              }
            }
            input + .formTitle,
            .divide + .formTitle {
              padding-top: 32px;
              margin-top: 32px;
              border-top: 1px solid #ebebeb;
            }
            label {
              display: block;
              margin-bottom: 8px;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              span {
                color: #fb3b3b;
              }
            }
            input,
            select,
            textarea {
              width: 100%;
              padding: 12px 16px;
              border-radius: 4px;
              border: 1px solid #e1e1e1;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
            input + label,
            .error + label,
            select + label,
            textarea + label,
            .divide + label {
              margin-top: 32px;
            }
            input + .error,
            .divide + .error {
              color: #fb3b3b;
              font-size: 14px;
              padding-top: 10px;
              display: block;
            }
            select {
              width: 100%;
            }
            .divide {
              display: flex;
              align-items: center;
              input {
                margin-bottom: 0;
              }
            }

            .error + .formTitle {
              padding-top: 32px;
            }
            .terms {
              display: flex;
              justify-content: start;
              align-items: flex-start;
              padding-top: 32px;
              margin-top: 32px;
              padding-bottom: 24px;
              border-top: 1px solid #ebebeb;
              input {
                width: 20px;
                height: 20px;
                margin-top: 3px;
              }
              label {
                margin-left: 8px;
                margin-top: 0;
                p {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  margin-bottom: 4px;
                  strong {
                    color: #6079ff;
                    cursor: pointer;
                    text-decoration: underline;
                  }
                }
              }
              span {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                color: #909090;
              }
            }
            button {
              width: 100%;
              border-radius: 27px;
              padding: 12px 32px;
              align-items: center;
              color: #fff;
              font-size: 20px;
              font-weight: 700;
              line-height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              background: #6079ff;
              border: 0;
              svg {
                margin-left: 6px;
              }
            }
          }
        }
      }
    }
    ${mq.mobile} {
      flex-direction: column;
      .wrap {
        width: 100%;
        padding: 96px 20px;
        .title {
          font-size: 32px;
          line-height: 48px;
          margin-left: 0;
        }
        .box {
          margin-top: 40px;
          flex-direction: column;
          .subTitle {
            font-size: 24px;
            line-height: 36px;
          }
          .process {
            width: 100%;
            .step {
              margin-top: 20px;
              gap: 36px;
              li {
                width: 100%;
                padding: 20px 32px;
                .text {
                  margin-left: 20px;
                  .sub {
                    font-size: 14px;
                    line-height: 20px;
                  }
                  .title {
                    margin-top: 4px;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                  }
                }
                .arrow {
                  position: absolute;
                  width: 32px;
                  height: 32px;
                  overflow: hidden;
                  bottom: -32px;
                  left: 50%;
                  transform: translateX(-50%);
                  svg {
                    position: absolute;
                    bottom: 0;
                  }
                }
              }
            }
            .notice {
              margin-top: 20px;
              padding: 20px;
              strong {
                font-size: 18px;
                line-height: 27px;
                padding-bottom: 8px;
              }
              li {
                font-size: 12px;
                line-height: 18px;
                padding-left: 15px;
              }
              li:before {
                left: 5px;
                width: 3px;
                height: 3px;
              }
            }
          }
          .form {
            width: 100%;
            .subTitle {
              font-size: 24px;
              font-weight: 700;
              line-height: 36px;
              padding-bottom: 25px;
              border-bottom: 1px solid #ebebeb;
            }
            form {
              margin-top: 42px;
              padding: 32px 20px;
              .formTitle {
                font-size: 18px;
                line-height: 27px;
                padding-top: 32px;
                span {
                  font-size: 14px;
                  line-height: 21px;
                }
              }
              label {
                margin-bottom: 11px;
                font-size: 14px;
                line-height: 21px;
              }
              input,
              select,
              textarea {
                font-size: 14px;
                line-height: 21px;
              }
              input + .error,
              .divide + .error {
                font-size: 12px;
              }
              .terms {
                padding-bottom: 32px;
                p {
                  font-size: 14px;
                  line-height: 21px;
                }
                span {
                  font-size: 12px;
                  line-height: 18px;
                }
              }
              button {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  `;

  const ModalStyle = css`
    .close-button {
      cursor: pointer;
    }
    .modal-content {
      display: flex;
      flex-direction: column;
      gap: 32px;
      .item {
        .item-title {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }
        .item-content {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
      .sub-text {
        color: #818181;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }
  `;

  const Variants = {
    offscreen: {
      y: 50,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div css={Store2Style}>
      <div className="wrap" id="storeForm">
        <h2 className="title">입점 안내</h2>
        <motion.div className="box">
          <motion.div
            className="process"
            initial="offscreen"
            whileInView="onscreen"
            variants={Variants}
            viewport={{ once: false }}
            transition={{
              ease: 'easeInOut',
              duration: 0.5,
              y: { duration: 0.5 },
            }}
          >
            <h3 className="subTitle">입점 프로세스 안내</h3>
            {/* <img src={list1Img} alt="list1" title="list1" /> */}
            <ul className="step">
              <li>
                <div className="img">
                  <Pencil />
                </div>
                <div className="text">
                  <p className="sub">STEP. 1</p>
                  <p className="title">입점 신청서 제출</p>
                </div>
                <div className="arrow">
                  <Down />
                </div>
              </li>
              <li>
                <div className="img">
                  <Tray />
                </div>
                <div className="text">
                  <p className="sub">STEP. 2</p>
                  <p className="title">MD 검토 및 결과 발송 </p>
                </div>
                <div className="arrow">
                  <Down />
                </div>
              </li>
              <li>
                <div className="img">
                  <User />
                </div>
                <div className="text">
                  <p className="sub">STEP. 3</p>
                  <p className="title">어드민 회원가입</p>
                </div>
                <div className="arrow">
                  <Down />
                </div>
              </li>
              <li className="on">
                <div className="img">
                  <Check />
                </div>
                <div className="text">
                  <p className="sub">STEP. 4</p>
                  <p className="title">전자 계약 체결 및 최종 입점 승인</p>
                </div>
              </li>
            </ul>
            <ul className="notice">
              <strong>🚨 입점 신청 전에 반드시 확인해 주세요!</strong>
              <li>
                입점 신청 후 승인까지 최대 2주 소요될 수 있습니다.
                <br />
                순차 검토 후 개별 연락드립니다.
              </li>
              <li>
                몰파이 입점 시, 다양한 채널에 상품이 노출될 수 있습니다.
                <br />
                단, 오픈된 온라인 플랫폼에 검색 및 가격이 노출되지 않습니다.
              </li>
              <li>간이 과세자의 경우, 상품 판매를 진행할 수 없습니다.</li>
            </ul>
          </motion.div>
          <motion.div
            className="form"
            initial="offscreen"
            whileInView="onscreen"
            variants={Variants}
            viewport={{ once: false }}
            transition={{
              ease: 'easeInOut',
              duration: 0.5,
              y: { duration: 0.5 },
            }}
          >
            {!isMobile && <h3 className="subTitle">바로 입점 신청하기</h3>}

            <form onSubmit={handleSubmit(onSubmit)}>
              {isMobile && <div className="subTitle">입점 신청</div>}
              <p className="formTitle">
                입점사 정보
                <br />
                <span>신청 카테고리기준으로 담당 MD가 검토 예정으로</span>
                <br />
                <span>주력 상품 위주로 간결하게 입력해 주세요.</span>
              </p>
              <label>
                회사명<span>*</span>
              </label>
              <input
                type="text"
                placeholder="회사명을 입력해주세요."
                {...register('compNm', { required: true, maxLength: 10 })}
              />
              <label>
                상품 공급 유형<span>*</span>
              </label>
              <select {...register('type1', { required: true })}>
                <option value="" disabled>
                  선택하세요
                </option>
                <option value="브랜드사/제조사">브랜드사 / 제조사</option>
                <option value="총판">총판</option>
                <option value="밴더">벤더</option>
                <option value="수입사">수입사</option>
              </select>
              <label>
                주력 카테고리<span>*</span>
              </label>
              <select {...register('type2', { required: true })}>
                <option value="" disabled>
                  선택하세요
                </option>
                <option value="식품, 여행/티켓">식품, 여행/티켓</option>
                <option value="생활/건강, 출산/육아">
                  생활/건강, 출산/육아
                </option>
                <option value="디지털/가전, 캠핑">디지털/가전, 캠핑</option>
                <option value="패션 의류/잡화, 스포츠/레저">
                  패션 의류/잡화, 스포츠/레저
                </option>
                <option value="가구/인테리어, 화장품/미용">
                  가구/인테리어, 화장품/미용
                </option>
                <option value="기타">기타</option>
              </select>
              <label>
                주력 상품 정보<span>*</span>
              </label>
              <textarea
                placeholder="운영 브랜드, 주력 상품, 보유한 상품 개수, 대략적인 매출액 등 간략한 내용"
                id="content"
                rows={5}
                {...register('content', {
                  required: true,
                })}
              />
              <label>자사몰 URL</label>
              <input
                type="text"
                placeholder="운영중인 쇼핑몰 주소를 입력해 주세요"
                {...register('compUrl')}
              />
              <div className="formTitle">
                담당자 정보
                <br />
                <span>신청 이후의 진행 상황이 해당 정보로 안내될 예정으로</span>
                <br />
                <span>정확한 정보로 입력해 주세요.</span>
              </div>
              <label>
                담당자 명 / 직책<span>*</span>
              </label>
              <div className="divide">
                <input
                  type="text"
                  placeholder="담당자명"
                  {...register('name', { required: true })}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  type="text"
                  placeholder="직책"
                  {...register('position', { required: true })}
                />
              </div>
              <label>
                연락처<span>*</span>
              </label>
              <input
                type="text"
                placeholder="000-0000-0000"
                {...register('mobile', {
                  required: true,
                  pattern: {
                    value: /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/,
                    message: '연락처 형식이 맞지 않습니다.',
                  },
                })}
              />
              {errors.mobile && (
                <span role="alert" className="error">
                  연락처 형식이 맞지 않습니다.
                </span>
              )}
              <label>
                이메일 주소<span>*</span>
              </label>
              <div className="divide">
                <input
                  type="text"
                  {...register('id', {
                    required: true,
                    pattern: {
                      value: /^[A-Za-z0-9.-]*$/,
                      message: '이메일 형식이 맞지 않습니다.',
                    },
                  })}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;@&nbsp;&nbsp;&nbsp;&nbsp;
                <input type="text" {...register('id2', { required: true })} />
              </div>
              {errors.id && (
                <span role="alert" className="error">
                  이메일 형식이 맞지 않습니다.
                </span>
              )}
              <div className="formTitle">
                문의 사항
                <br />
                <span>자주 묻는 질문은 아래 FAQ에 정리되어 있습니다.</span>
                <br />
                <span>이 외 문의 사항이 있다면 간략하게 입력해 주세요.</span>
              </div>
              <textarea
                placeholder="문의사항을 입력해주세요."
                id="inquiry"
                rows={5}
                {...register('inquiry')}
              />
              <div className="terms">
                <input
                  type="checkBox"
                  value="Y"
                  {...register('term')}
                  id="term"
                />
                <label htmlFor="term" className="term">
                  <p>
                    [필수]{' '}
                    <strong onClick={handleModalOpen}>
                      개인(신용)정보 수집 · 이용
                    </strong>
                    에 동의 합니다.
                  </p>
                  <span>*입점 검토 외 다른 목적으로 사용하지 않습니다.</span>
                </label>
              </div>
              <button disabled={isSubmiting}>
                제출하기
                <ArrowLeft />
              </button>
            </form>
          </motion.div>
        </motion.div>
      </div>
      <Modal
        open={modal}
        enableButton={true}
        onClose={handleModalClose}
        color="blue"
      >
        <div css={ModalStyle}>
          <div className="title close-icon">
            입점 신청을 위한 정보 수집 및 이용 동의
            <div className="close-button" onClick={handleModalClose}>
              <Close />
            </div>
          </div>
          <div className="modal-content">
            <div className="item">
              <div className="item-title">목적</div>
              <div className="item-content">
                입점 신청 검토 및 응답, 문의 사항 답변 등
              </div>
            </div>
            <div className="item">
              <div className="item-title">항목</div>
              <div className="item-content">
                기업명, 상품 공급 유형, 카테고리, 상품 정보, 담당자명, 연락처,
                이메일 주소
              </div>
            </div>
            <div className="item">
              <div className="item-title">보유/이용기간</div>
              <div className="item-content">
                상담 서비스가 종료되는 시점
                <br />
                *관계 법률에 의해 보존할 필요가 있는 경우 일정 기간 보존
              </div>
            </div>
            <div className="sub-text">
              ※ 위 개인정보 수집 · 이용에 대한 동의를 거부할 수 있으며 거부할
              경우 입점 신청 및 문의 등이 제한됩니다.
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Store2;
