import Meta from 'components/common/Meta';
import Layout from 'components/common/NewLayout';
import Store1 from 'components/store/Store1';
import Store2 from 'components/store/Store2';
import Store3 from 'components/store/Store3';
import Store4 from 'components/store/Store4';
import Title from 'components/store/Title';
import axios from 'core/config/axios';
import { useIsMobile } from 'core/hooks/useResponsive';
import moment from 'moment';
import React, { useState } from 'react';

const Store = () => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const isMobile = useIsMobile();

  const onSubmit = data => {
    if (data.term !== 'Y') {
      window.alert('개인정보 이용에 동의해주세요');
      return;
    }
    setIsSubmiting(true);

    const createQuery = `mutation { create_item (board_id: 6870866777, group_id: "topics", item_name: "[몰파이] 입점제안서 - 공통에 새 응답이 접수되었습니다.") { id }}`;

    axios
      .post(
        'https://api.monday.com/v2',
        JSON.stringify({
          query: createQuery,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjMwMDI5NTIwMSwiYWFpIjoxMSwidWlkIjozNTQ3OTEyNCwiaWFkIjoiMjAyMy0xMi0wNFQwMTowNTo1NS4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTI2MzQxNjksInJnbiI6InVzZTEifQ.IgFcORzpDWwyO3pbRP4NdoF4YuosvnSaxID3qrwBwP8',
          },
        },
      )
      .then(res => {
        const updateQuery = `mutation { create_update (item_id: ${res.data.data.create_item.id}, body: "회사명: ${data.compNm}<br/> 상품 공급 유형: ${data.type1}<br/> 주력 카테고리: ${data.type2}<br/> 주력 상품 정보: ${data.content}<br/> 자사몰 URL: ${data.compUrl}<br/><br/> 담당자 명 / 직책: ${data.name}/${data.position} <br/> 연락처: ${data.mobile} <br/> 이메일 주소: ${data.id}@${data.id2} <br/> 문의사항 :<br/> ${data.inquiry}") { id }}`;
        axios
          .post(
            'https://api.monday.com/v2',
            JSON.stringify({
              query: updateQuery,
            }),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization:
                  'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjMwMDI5NTIwMSwiYWFpIjoxMSwidWlkIjozNTQ3OTEyNCwiaWFkIjoiMjAyMy0xMi0wNFQwMTowNTo1NS4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTI2MzQxNjksInJnbiI6InVzZTEifQ.IgFcORzpDWwyO3pbRP4NdoF4YuosvnSaxID3qrwBwP8',
              },
            },
          )
          .then(() => {
            alert('입점 신청이 완료 되었습니다.');
            window.location.reload();
          })
          .catch(error => {
            setIsSubmiting(false);
          });
      });
  };
  return (
    <Layout theme="black" headerTheme="black">
      <Meta title="입점센터 | 몰파이" ogTitle="입점센터 | 몰파이" />
      <Title />
      <Store1 />
      <Store2 onSubmit={onSubmit} isSubmiting={isSubmiting} />
      <Store3 />
      <Store4 />
    </Layout>
  );
};

export default Store;
